import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FaChevronUp } from 'react-icons/fa';

const blockBg = img =>
  `url(${img}) center / cover no-repeat fixed padding-box content-box black`;

const HomeIcon = ({ to, label }) => {


  return (
    <AniLink
      paintDrip
      
      to={to}
      duration={1.5}
      hex="#de8014"
    >
      <div className="flex items-center justify-center h-screen fixed w-8 bg-gray-900 group">
        <div className="flex items-center uppercase rotate-z-270 whitespace-no-wrap text-white">
          <span className="transition text-gray-900 w-0 group-hover:text-white group-hover:w-32 vivo">
            {label}
          </span>
          <FaChevronUp />
        </div>
      </div>
    </AniLink>
  );
};

HomeIcon.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default HomeIcon;
